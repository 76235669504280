import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#2C2C2C', // Soft dark gray for a neutral backdrop
      paper: '#3A3A3A', // Slightly lighter gray for contrast
    },
    primary: {
      main: '#A4C4F9', // Pastel sky blue for primary elements
    },
    secondary: {
      main: '#F2B5D4', // Soft pastel pink for secondary elements
    },
    error: {
      main: '#F28482', // Gentle pastel red for errors
    },
    warning: {
      main: '#F7C873', // Warm pastel yellow for warnings
    },
    success: {
      main: '#A4F3B0', // Soft pastel green for success
    },
    text: {
      primary: '#E0E0E0', // Off-white for readability
      secondary: '#B0BEC5', // Muted light gray for secondary text
    },
  },
  typography: {
    fontFamily: 'Inter, Roboto, sans-serif', // Sleek and clean font style
    h1: {
      fontWeight: 700,
      fontSize: '2.5rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1.5rem',
    },
    h6: {
      fontWeight: 400,
      fontSize: '1.25rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  shape: {
    borderRadius: 8, // Slightly rounded edges for modern design
  },
  shadows: Array(25).fill('none'), // Eliminates harsh shadows
});

export default theme;
