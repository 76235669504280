// Start of Selection
import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { io } from 'socket.io-client';
import { Typography, Paper, Grid, Snackbar, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import Terminal from '../Terminal/Terminal';

/**
 * Constants for WebSocket connection and log handling
 */
const SOCKET_URL = 'https://2layers.io';
const RECONNECT_DELAY = 5000; // 5 seconds delay between reconnection attempts

/**
 * Logs component connects to a WebSocket and displays server logs along with a command interface.
 * @param {Object} props
 * @param {string} props.container_name - The name of the container to subscribe to.
 * @param {string} props.client_server_ip - The IP address of the server to send commands to.
 * @returns {JSX.Element}
 */
function Logs({ container_name, client_server_ip }) {
    const theme = useTheme();
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [isConnected, setIsConnected] = useState(false);
    const socketRef = useRef(null);
    const terminalRef = useRef(null);
    const commandBuffer = useRef('');
    const reconnectTimeoutRef = useRef(null);

    // Memoize the terminal options to prevent re-initialization on every render
    const terminalOptions = useMemo(() => ({
        theme: {
            background: theme.palette.background.paper,
            foreground: theme.palette.text.primary,
        },
        fontSize: 14,
        fontFamily: '"Consolas", monospace',
        cursorBlink: true,
    }), [theme.palette.background.paper, theme.palette.text.primary]);

    // Memoize the handler to ensure it doesn't get recreated unnecessarily
    const handleTerminalInput = useCallback(async (data) => {
        if (data === '\r') {
            // user pressed Enter
            const command = commandBuffer.current;
            commandBuffer.current = '';

            if (command) {
                try {
                    const response = await axios.post(`/api/server/${client_server_ip}/command`, {
                        command: command,
                    });
                    if (response.status === 200) {
                        setSnackbar({ 
                            open: true, 
                            message: 'Command sent successfully.', 
                            severity: 'success' 
                        });
                    } else {
                        const errorMsg = response.data.error 
                            ? response.data.error 
                            : 'Failed to send command.';
                        setSnackbar({ 
                            open: true, 
                            message: `Failed to send command. ${errorMsg}`, 
                            severity: 'error' 
                        });
                    }
                } catch (error) {
                    console.error('Error sending command:', error);
                    let serverErrorMessage = 'An error occurred while sending the command.';
                    if (error.response?.data?.error) {
                        serverErrorMessage = error.response.data.error;
                    }
                    setSnackbar({ 
                        open: true, 
                        message: serverErrorMessage, 
                        severity: 'error'
                    });
                }
            }
        } else if (data === '\b') {
            // Backspace
            if (commandBuffer.current.length > 0) {
                commandBuffer.current = commandBuffer.current.slice(0, -1);
            }
        } else if (data === '\u0003') {
            // Ctrl+C
            commandBuffer.current = '';
        } else {
            // Normal character
            commandBuffer.current += data;
        }
    }, [client_server_ip]);

    /**
     * Attempts to reconnect to the WebSocket server
     */
    const attemptReconnect = useCallback(() => {
        if (reconnectTimeoutRef.current) {
            return; // Already attempting to reconnect
        }

        console.log('Scheduling reconnection attempt...');
        reconnectTimeoutRef.current = setTimeout(() => {
            console.log('Attempting to reconnect...');
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
            initializeSocket();
            reconnectTimeoutRef.current = null;
        }, RECONNECT_DELAY);
    }, []);

    /**
     * Initializes the WebSocket connection.
     */
    const initializeSocket = useCallback(() => {
        if (socketRef.current?.connected) {
            console.log('Socket already connected');
            return;
        }

        try {
            const socket = io(SOCKET_URL, {
                path: '/socket.io',
                transports: ['websocket']
            });

            socket.on('connect', () => {
                console.log('Connected to logs WebSocket');
                setIsConnected(true);
                if (container_name) {
                    socket.emit('subscribe', { container_name });
                }
            });

            socket.on('disconnect', (reason) => {
                console.log(`Disconnected from logs WebSocket: ${reason}`);
                setIsConnected(false);
                attemptReconnect();
            });

            socket.on('connect_error', (error) => {
                console.error('Connection error:', error);
                setIsConnected(false);
                attemptReconnect();
            });

            socket.on('logs', (data) => {
                const log = data.message || '';
                if (terminalRef.current) {
                    terminalRef.current.write(log);
                }
            });

            socket.on('subscribed', (data) => {
                console.log(`Subscribed to container: ${data.container}`);
                setSnackbar({
                    open: true,
                    message: `Connected to container ${data.container}`,
                    severity: 'success'
                });
            });

            socket.on('error', (data) => {
                console.error("Socket error:", data.error);
                setSnackbar({
                    open: true,
                    message: `Socket error: ${data.error}`,
                    severity: 'error'
                });
                attemptReconnect();
            });

            socketRef.current = socket;

        } catch (error) {
            console.error('Socket initialization error:', error);
            setIsConnected(false);
            attemptReconnect();
        }
    }, [container_name, attemptReconnect]);

    useEffect(() => {
        initializeSocket();

        return () => {
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            if (socketRef.current) {
                if (container_name) {
                    socketRef.current.emit('unsubscribe', { container_name });
                }
                socketRef.current.disconnect();
                console.log('Cleaned up logs WebSocket connection');
            }
        };
    }, [container_name, initializeSocket]);

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Paper
            elevation={3}
            sx={{
                padding: theme.spacing(2),
                marginTop: theme.spacing(1),
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '800px',
                width: '100%',
                height: '600px',
                margin: '0 auto'
            }}
        >
            <Terminal
                ref={terminalRef}
                options={terminalOptions}
                onData={handleTerminalInput}
            />
            <Snackbar
                open={snackbar.open}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '90%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Paper>
    );
}

export default Logs;