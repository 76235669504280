export const DIFFICULTIES = [
  { value: 'peaceful', label: 'Peaceful' },
  { value: 'easy', label: 'Easy' },
  { value: 'normal', label: 'Normal' },
  { value: 'hard', label: 'Hard' },
];

export const GAMEMODES = [
  { value: 'survival', label: 'Survival' },
  { value: 'creative', label: 'Creative' },
  { value: 'adventure', label: 'Adventure' },
  { value: 'spectator', label: 'Spectator' },
];

export const BOOLEAN_PROPERTIES = [
  { label: 'Allow Flight', prop: 'allow_flight' },
  { label: 'Allow Nether', prop: 'allow_nether' },
  { label: 'Enable Command Block', prop: 'enable_command_block' },
  { label: 'Force Gamemode', prop: 'force_gamemode' },
  { label: 'PVP', prop: 'pvp' },
  { label: 'Spawn Animals', prop: 'spawn_animals' },
  { label: 'Spawn Monsters', prop: 'spawn_monsters' },
  { label: 'Spawn NPCs', prop: 'spawn_npcs' },
  { label: 'White List', prop: 'white_list' },
];

export const NUMERIC_PROPERTIES = [
  { label: 'Max Players', prop: 'max_players', min: 1 },
  { label: 'View Distance', prop: 'view_distance', min: 2 },
  { label: 'Spawn Protection', prop: 'spawn_protection', min: 0 },
];

export const DEFAULT_PROPERTIES = {
  allow_flight: true,
  allow_nether: true,
  difficulty: 'hard',
  enable_command_block: false,
  force_gamemode: false,
  gamemode: 'survival',
  max_players: 10,
  pvp: true,
  spawn_animals: true,
  spawn_monsters: true,
  spawn_npcs: true,
  view_distance: 6,
  white_list: false,
  spawn_protection: 16,
}; 