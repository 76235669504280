import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Typography,
  Grid,
  CircularProgress,
  Box,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  Alert
} from '@mui/material';
import ServerPropertiesForm from '../server/ServerPropertiesForm';
import Stats from './Stats';
import { DEFAULT_PROPERTIES } from '../server/serverProperties';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const MAX_RAM = 8;
const MAX_CPU = 4;

/**
 * Settings component allows users to view and modify server settings.
 * @param {Object} props
 * @param {string} props.user_id - The ID of the current user.
 * @param {string} props.client_server_ip - The IP address of the server.
 * @returns {JSX.Element}
 */
function Settings({ user_id, client_server_ip }) {
  const [client_subdomain, set_subdomain] = useState('');
  const [server_properties, set_server_properties] = useState(null);
  const [is_loading, set_is_loading] = useState(true);
  const [ram, set_ram] = useState(2);
  const [cpu, set_cpu] = useState(1);
  const [versions_by_type, set_versions_by_type] = useState({});
  const [selected_type, set_selected_type] = useState('');
  const [selected_version, set_selected_version] = useState('');
  const [available_versions, set_available_versions] = useState([]);

  // Snackbar for success/error notifications
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Delete confirmation dialog states
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    const fetch_versions = async () => {
      try {
        const response = await axios.get('/api/versions');
        set_versions_by_type(response.data.versions);
      } catch (error) {
        console.error('Error fetching versions:', error);
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          'Error fetching versions.';
        setSnackbar({
          open: true,
          message: errorMessage,
          severity: 'error'
        });
      }
    };
    fetch_versions();
  }, []);

  useEffect(() => {
    if (selected_type && versions_by_type[selected_type]) {
      set_available_versions(versions_by_type[selected_type]);
    }
  }, [selected_type, versions_by_type]);

  useEffect(() => {
    const fetch_server_details = async () => {
      try {
        const response = await axios.get(`/api/server/${client_server_ip}`, {
          params: { user_id }
        });
        const server = response.data.server;

        set_subdomain(server.client_subdomain);

        // Server properties are now returned directly as an object
        if (server.properties) {
          set_server_properties(server.properties.SERVER_PROPERTIES);
          // Set server type and version from properties
          if (server.properties.VERSION) {
            const [type, version] = server.properties.VERSION.split('/');
            set_selected_type(type);
            set_selected_version(version);
          }
        } else {
          set_server_properties({ ...DEFAULT_PROPERTIES });
        }

        set_ram(server.ram);
        set_cpu(server.cpu);
        set_is_loading(false);
      } catch (error) {
        console.error('Error fetching server details:', error);
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          'Failed to fetch server details.';
        setSnackbar({
          open: true,
          message: errorMessage,
          severity: 'error'
        });
        set_is_loading(false);
      }
    };

    fetch_server_details();
  }, [user_id, client_server_ip]);

  const handle_save_changes = async () => {
    try {
      // Fetch existing server properties to preserve other sub-properties
      const response = await axios.get(`/api/server/${client_server_ip}`, {
        params: { user_id }
      });
      const existingServer = response.data.server || {};
      const existingServerProperties = existingServer.properties || {};

      // Merge existing properties with the updated SERVER_PROPERTIES and VERSION
      const updatedServerProperties = {
        ...existingServerProperties,
        SERVER_PROPERTIES: server_properties,
        VERSION:
          selected_type && selected_version
            ? `${selected_type}/${selected_version}`
            : existingServerProperties.VERSION
      };

      await axios.post(`/api/server/${client_server_ip}/update`, {
        user_id,
        client_subdomain: client_subdomain,
        server_properties: updatedServerProperties,
        ram,
        cpu
      });
      setSnackbar({
        open: true,
        message: 'Server settings updated successfully.',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error updating server settings:', error);
      const errorMessage =
        error.response?.data?.error ||
        error.message ||
        'Failed to update server settings.';
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
    }
  };

  const handle_delete_server = async () => {
    // Require the user to type their server subdomain or IP exactly
    if (
      confirmationText !== client_subdomain &&
      confirmationText !== client_server_ip
    ) {
      setSnackbar({
        open: true,
        message: 'Please type your subdomain or IP exactly to confirm.',
        severity: 'error'
      });
      return;
    }
    try {
      await axios.post(`/api/server/${client_server_ip}/delete`, { user_id });
      setSnackbar({
        open: true,
        message: 'Server deleted successfully.',
        severity: 'success'
      });
      setOpenDeleteDialog(false);
      navigate('/server');
    } catch (error) {
      console.error('Error deleting server:', error);
      const errorMessage =
        error.response?.data?.error ||
        error.message ||
        'Failed to delete server.';
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
    }
  };

  if (is_loading || !server_properties) {
    return (
      <Grid container justifyContent="center" sx={{ marginTop: theme.spacing(4) }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box sx={{ width: '100%', overflowX: 'hidden' }}>
      <Typography variant="h5" gutterBottom>
        Server Settings
      </Typography>

      <Grid container spacing={3} sx={{ marginTop: theme.spacing(2) }}>
        {/* Basic Settings */}
        <Grid item xs={12}>
          <Typography variant="h6">Basic Settings</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Subdomain"
            variant="outlined"
            fullWidth
            value={client_subdomain}
            onChange={(e) => set_subdomain(e.target.value)}
          />
        </Grid>

        {/* Server Version Selection */}
        <Grid item xs={12}>
          <Typography variant="h6">Server Version</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            label="Server Type"
            variant="outlined"
            fullWidth
            value={selected_type}
            onChange={(e) => set_selected_type(e.target.value)}
          >
            {Object.keys(versions_by_type).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            label="Version"
            variant="outlined"
            fullWidth
            value={selected_version}
            onChange={(e) => set_selected_version(e.target.value)}
            disabled={!selected_type}
          >
            {available_versions.map((version) => (
              <MenuItem key={version} value={version}>
                {version}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {/* Resource Settings */}
        <Grid item xs={12}>
          <Typography variant="h6">Resource Settings</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="RAM (GB)"
            type="number"
            variant="outlined"
            fullWidth
            value={ram}
            onChange={(e) => set_ram(Math.min(Number(e.target.value), MAX_RAM))}
            inputProps={{ min: 1, max: MAX_RAM }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="CPU Cores"
            type="number"
            variant="outlined"
            fullWidth
            value={cpu}
            onChange={(e) => set_cpu(Math.min(Number(e.target.value), MAX_CPU))}
            inputProps={{ min: 1, max: MAX_CPU }}
          />
        </Grid>

        {/* Server Properties */}
        <Grid item xs={12}>
          <Typography variant="h6">Server Properties</Typography>
        </Grid>
        <Grid item xs={12}>
          <ServerPropertiesForm
            properties={server_properties}
            onChange={set_server_properties}
          />
        </Grid>

        {/* Save Button */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handle_save_changes}
            fullWidth
            size="large"
          >
            Save Changes
          </Button>
        </Grid>

        {/* Server Statistics */}
        <Grid item xs={12}>
          <Stats container_name={client_server_ip} />
        </Grid>

        {/* Delete Button */}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            size="large"
            onClick={() => setOpenDeleteDialog(true)}
          >
            Delete Server
          </Button>
        </Grid>
      </Grid>

      {/* Confirm Delete Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Server Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please type your subdomain or server IP exactly to confirm deletion.
            This action cannot be undone.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Subdomain or IP"
            fullWidth
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handle_delete_server} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '90%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Settings;