/**
 * Note: This rewrite pins the cursor at the bottom of the terminal at all times,
 * provides simple command history (Up/Down arrow keys), and ensures the
 * terminal behaves more like a typical shell.
 */
import React, { useEffect, useRef } from 'react';
import { Terminal as XTerm } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import 'xterm/css/xterm.css';

/**
 * Terminal Component
 * @param {Object} props
 * @param {Object} props.options - Configuration options for the terminal.
 * @param {Function} props.onData - Callback for handling input data (e.g., commands).
 * @param {React.Ref} ref - Ref forwarded to access terminal instance methods.
 * @returns {JSX.Element}
 */
const Terminal = ({ options = {}, onData }, ref) => {
    const terminalRef = useRef(null);
    const xtermRef = useRef(null);
    const fitAddonRef = useRef(null);

    // Maintain a simple history of commands and cursor position
    const historyRef = useRef([]);
    const historyIndexRef = useRef(-1);
    const currentInputRef = useRef('');
    const cursorPosRef = useRef(0);

    useEffect(() => {
        fitAddonRef.current = new FitAddon();
        xtermRef.current = new XTerm({
            convertEol: true,
            scrollback: 1000,
            cursorBlink: true,
            disableStdin: true, // we manage keystrokes manually
            ...options,
        });
        xtermRef.current.loadAddon(fitAddonRef.current);

        if (terminalRef.current) {
            xtermRef.current.open(terminalRef.current);
            setTimeout(() => {
                fitAddonRef.current.fit();
                xtermRef.current.scrollToBottom();
            }, 100);
        }

        // Keyboard handling
        xtermRef.current.onKey(({ key, domEvent }) => {
            const printable = !domEvent.altKey && !domEvent.ctrlKey && !domEvent.metaKey;

            // Up Arrow
            if (domEvent.key === 'ArrowUp') {
                if (historyIndexRef.current > 0) {
                    historyIndexRef.current -= 1;
                    recallCommand(historyRef.current[historyIndexRef.current] || '');
                }
            }
            // Down Arrow
            else if (domEvent.key === 'ArrowDown') {
                if (historyIndexRef.current < historyRef.current.length - 1) {
                    historyIndexRef.current += 1;
                    recallCommand(historyRef.current[historyIndexRef.current] || '');
                } else {
                    historyIndexRef.current = historyRef.current.length;
                    recallCommand('');
                }
            }
            // Left Arrow
            else if (domEvent.key === 'ArrowLeft') {
                if (cursorPosRef.current > 0) {
                    cursorPosRef.current--;
                    xtermRef.current.write('\b');
                }
            }
            // Right Arrow
            else if (domEvent.key === 'ArrowRight') {
                if (cursorPosRef.current < currentInputRef.current.length) {
                    xtermRef.current.write(currentInputRef.current[cursorPosRef.current]);
                    cursorPosRef.current++;
                }
            }
            // Enter
            else if (domEvent.key === 'Enter') {
                const command = currentInputRef.current;
                xtermRef.current.write('\r\n');

                if (command) {
                    historyRef.current.push(command);
                    historyIndexRef.current = historyRef.current.length;
                }

                // Pass the Enter input upstream
                onData?.('\r');

                currentInputRef.current = '';
                cursorPosRef.current = 0;
                xtermRef.current.scrollToBottom();
            }
            // Backspace
            else if (domEvent.key === 'Backspace') {
                if (cursorPosRef.current > 0) {
                    const beforeCursor = currentInputRef.current.slice(0, cursorPosRef.current - 1);
                    const afterCursor = currentInputRef.current.slice(cursorPosRef.current);
                    currentInputRef.current = beforeCursor + afterCursor;
                    cursorPosRef.current--;

                    // Let parent know a backspace occurred
                    onData?.('\b');
                    
                    // Erase visually
                    xtermRef.current.write('\b \b');
                }
                xtermRef.current.scrollToBottom();
            }
            // Printable characters
            else if (printable) {
                // Insert at cursor position
                const beforeCursor = currentInputRef.current.slice(0, cursorPosRef.current);
                const afterCursor = currentInputRef.current.slice(cursorPosRef.current);
                currentInputRef.current = beforeCursor + key + afterCursor;
                cursorPosRef.current++;

                // Echo to terminal (local echo)
                xtermRef.current.write(key);

                // Pass typed char upstream
                onData?.(key);

                xtermRef.current.scrollToBottom();
            }
            
            // Always fit terminal after keypress and ensure at bottom
            fitAddonRef.current.fit();
            xtermRef.current.scrollToBottom();
        });

        // Fit on container resize
        const handleResize = () => {
            fitAddonRef.current.fit();
            xtermRef.current.scrollToBottom();
        };

        const ro = new ResizeObserver(handleResize);
        if (terminalRef.current) ro.observe(terminalRef.current);

        window.addEventListener('resize', handleResize);
        if (window.visualViewport) {
            window.visualViewport.addEventListener('resize', handleResize);
        }

        return () => {
            ro.disconnect();
            window.removeEventListener('resize', handleResize);
            if (window.visualViewport) {
                window.visualViewport.removeEventListener('resize', handleResize);
            }
            xtermRef.current?.dispose();
        };
    }, [options, onData]);

    const clearLine = () => {
        // Move cursor back
        for (let i = 0; i < cursorPosRef.current; i++) {
            xtermRef.current.write('\b');
        }
        // Clear the line
        for (let i = 0; i < currentInputRef.current.length; i++) {
            xtermRef.current.write(' ');
        }
        // Move back again
        for (let i = 0; i < currentInputRef.current.length; i++) {
            xtermRef.current.write('\b');
        }
    };

    const recallCommand = (newCommand) => {
        // If the parent needs to know about the old text being cleared:
        onData?.('\x1b[2K\r'); // optional: signals a line clear

        // Remove old input from the terminal's line
        clearLine();

        // Pass backspaces up so parent "knows" we removed old input
        if (onData) {
            for (let i = 0; i < currentInputRef.current.length; i++) {
                onData('\b');
            }
        }

        // Update local states
        currentInputRef.current = newCommand;
        cursorPosRef.current = newCommand.length;

        // Reprint new command
        xtermRef.current.write(newCommand);

        // Pass typed chars up
        if (onData) {
            for (const c of newCommand) {
                onData(c);
            }
        }
    };

    // Provide a write method for logs or any external text
    React.useImperativeHandle(ref, () => ({
        write: (data) => {
            if (!xtermRef.current) return;
            // Save local input
            const savedInput = currentInputRef.current;
            const savedCursorPos = cursorPosRef.current;

            // Temporarily remove user's partial input
            clearLine();

            // Write logs
            xtermRef.current.write(data);

            // Reprint user's typed input
            xtermRef.current.write(savedInput);

            // Move cursor back if needed
            const offset = savedInput.length - savedCursorPos;
            for (let i = 0; i < offset; i++) {
                xtermRef.current.write('\b');
            }

            // Scroll to bottom so cursor is visible
            xtermRef.current.scrollToBottom();
        }
    }), []);

    return (
        <div
            ref={terminalRef}
            style={{
                height: '100%',
                width: '100%',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column'
            }}
        />
    );
};

export default React.forwardRef(Terminal);