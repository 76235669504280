import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import { Container, Typography, Button, Paper, Snackbar, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Login({ set_user_id }) {
  const navigate = useNavigate();
  const theme = useTheme();

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handle_google_login_success = (credential_response) => {
    if (credential_response.credential) {
      const token = credential_response.credential;
      const decoded = jwt_decode(token);
      set_user_id(decoded.sub);
      // Save token to localStorage
      localStorage.setItem('token', token);

      // Show success snackbar
      setSnackbar({
        open: true,
        message: 'Login successful!',
        severity: 'success',
      });

      // Redirect to servers page
      navigate('/server');
    } else {
      console.error('Google login failed. No credential received.');
      setSnackbar({
        open: true,
        message: 'Google login failed. No credential received.',
        severity: 'error',
      });
    }
  };

  const handle_google_login_failure = () => {
    console.error('Google Login Failed');
    setSnackbar({
      open: true,
      message: 'Google Login Failed',
      severity: 'error',
    });
  };

  return (
    <>
      <Container maxWidth="sm" sx={{ marginTop: theme.spacing(12), textAlign: 'center' }}>
        <Paper elevation={3} sx={{ padding: theme.spacing(4) }}>
          <Typography variant="h4" gutterBottom>
            Sign in with Google
          </Typography>
          <GoogleLogin
            onSuccess={handle_google_login_success}
            onError={handle_google_login_failure}
            render={(renderProps) => (
              <Button
                variant="contained"
                color="primary"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                fullWidth
                size="large"
                sx={{ marginTop: theme.spacing(2) }}
              >
                Login with Google
              </Button>
            )}
          />
        </Paper>
      </Container>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '90%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Login;
