import React from 'react';
import {
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
} from '@mui/material';
import {
  DIFFICULTIES,
  GAMEMODES,
  BOOLEAN_PROPERTIES,
  NUMERIC_PROPERTIES,
} from './serverProperties';
import { useTheme } from '@mui/material/styles';

function ServerPropertiesForm({ properties, onChange, disabled = false }) {
  const theme = useTheme();

  const handle_property_change = (prop) => (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    onChange({ ...properties, [prop]: value });
  };

  if (!BOOLEAN_PROPERTIES || !Array.isArray(BOOLEAN_PROPERTIES)) {
    console.error('BOOLEAN_PROPERTIES is not properly defined');
    return null;
  }

  return (
    <Box sx={{ width: '100%', overflowX: 'hidden' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {BOOLEAN_PROPERTIES.map(({ label, prop }) => (
            <FormControlLabel
              key={prop}
              control={
                <Checkbox
                  checked={properties[prop] || false}
                  onChange={handle_property_change(prop)}
                  disabled={disabled}
                  color="primary"
                />
              }
              label={label}
            />
          ))}
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <TextField
            select
            label="Difficulty"
            variant="outlined"
            fullWidth
            value={properties.difficulty}
            onChange={handle_property_change('difficulty')}
            disabled={disabled}
          >
            {DIFFICULTIES.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Gamemode"
            variant="outlined"
            fullWidth
            value={properties.gamemode}
            onChange={handle_property_change('gamemode')}
            sx={{ marginTop: theme.spacing(2) }}
            disabled={disabled}
          >
            {GAMEMODES.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          {NUMERIC_PROPERTIES.map(({ label, prop, min }) => (
            <TextField
              key={prop}
              label={label}
              variant="outlined"
              type="number"
              fullWidth
              value={properties[prop]}
              onChange={handle_property_change(prop)}
              inputProps={{ min }}
              sx={{ marginTop: theme.spacing(2) }}
              disabled={disabled}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ServerPropertiesForm; 