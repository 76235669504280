import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Typography, Grid, Button, Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Backups({ user_id, client_server_ip }) {
  const theme = useTheme();
  const [backups, setBackups] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableTimes, setAvailableTimes] = useState([]);

  useEffect(() => {
    fetchBackups();
  }, [client_server_ip]);

  const fetchBackups = async () => {
    try {
      // Fetch the list of backups from the server
      const response = await fetch(`/api/server/${client_server_ip}/backups`);
      const data = await response.json();

      // Check if the response contains backups
      if (!data.backups || !Array.isArray(data.backups)) {
        console.error('No backups key in the returned data, or data is not an array:', data);
        setBackups([]);
        return;
      }

      // Otherwise, set backups to the returned array
      setBackups(data.backups);
    } catch (error) {
      // Log any error that occurs during fetch
      console.error('Error fetching backups:', error);
      setBackups([]);
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getDatesWithBackups = () => {
    // Convert the timestamp for each backup to a date string
    return backups.map(backup => {
      const date = new Date(backup.timestamp);
      return formatDate(date);
    });
  };

  const tileClassName = ({ date }) => {
    // If the date is in the list of backups, add a CSS class
    const dateStr = formatDate(date);
    return getDatesWithBackups().includes(dateStr) ? 'has-backup' : 'no-backup';
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    const dateStr = formatDate(date);

    // Filter only the backups that match the selected date
    const filteredBackups = backups.filter(backup => {
      return backup.timestamp.startsWith(dateStr);
    });

    // Convert them into a list of times from newest to oldest
    const timesForDate = filteredBackups
      .map(backup => ({
        time: backup.timestamp.split('T')[1].substring(0, 5),
        timestamp: backup.timestamp,
        path: backup.path
      }))
      .reverse();

    setAvailableTimes(timesForDate);
  };

  const handleTimeSelect = async (backupPath, timestamp) => {
    // Prompt the user to confirm the backup load
    const confirmLoad = window.confirm(`Do you want to load the backup from ${timestamp.replace('T', ' ')}?`);
    if (!confirmLoad) {
      return;
    }

    try {
      // Send a request to load the backup
      const response = await fetch(`/api/server/${client_server_ip}/load_backup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id,
          backup_timestamp: timestamp.replace('T', ' ')
        })
      });

      const data = await response.json();
      if (response.ok) {
        alert('Backup load initiated successfully.');
      } else {
        alert(`Error loading backup: ${data.error}`);
      }
    } catch (error) {
      // If there's an unexpected error, alert the user
      alert('An unexpected error occurred while loading the backup.');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4, color: theme.palette.text.primary }}>
        Server Backups
      </Typography>

      <Paper
        elevation={0}
        sx={{
          p: 3,
          backgroundColor: theme.palette.background.paper,
          borderRadius: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            '.react-calendar': {
              backgroundColor: theme.palette.background.default,
              border: `1px solid ${theme.palette.divider}`,
              width: '500px',
              color: theme.palette.text.primary,
              padding: '1rem'
            },
            '.react-calendar__tile': {
              color: theme.palette.text.secondary,
              padding: '1em 0.5em'
            },
            '.has-backup': {
              backgroundColor: `${theme.palette.primary.main}22`,
              color: theme.palette.text.primary,
              borderRadius: 1,
              fontWeight: 'bold'
            },
            '.no-backup': {
              backgroundColor: 'transparent'
            }
          }}
        >
          <Calendar
            onChange={handleDateClick}
            value={selectedDate}
            tileClassName={tileClassName}
          />
        </Box>

        {/* If the user has selected a date with one or more backups, display them */}
        {selectedDate && availableTimes.length > 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, color: theme.palette.text.secondary }}>
              Available Backups for {selectedDate.toLocaleDateString()}
            </Typography>
            <Grid container spacing={1} justifyContent="center">
              {availableTimes.map(({ time, path, timestamp }) => (
                <Grid item key={time}>
                  <Button
                    variant="outlined"
                    onClick={() => handleTimeSelect(path, timestamp)}
                    sx={{
                      px: 3,
                      py: 1,
                      borderColor: theme.palette.primary.main,
                      color: theme.palette.primary.main,
                      '&:hover': {
                        backgroundColor: `${theme.palette.primary.main}22`
                      }
                    }}
                  >
                    {time}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        {/* If the user selects a date with no backups, display a message */}
        {selectedDate && availableTimes.length === 0 && (
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ mb: 2, color: theme.palette.text.secondary }}>
              No backups found for {selectedDate.toLocaleDateString()}
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
}

export default Backups;