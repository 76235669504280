import React, { useEffect, useState, useCallback, useRef } from 'react';
import { io } from 'socket.io-client';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Typography, Paper, Grid, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SOCKET_URL = 'https://2layers.io';
const MAX_DATA_POINTS = 60;
const RECONNECT_DELAY = 5000; // 5 seconds delay between reconnection attempts

/**
 * Processes incoming stats data.
 * @param {Object} data - The raw stats data from the server.
 * @returns {Object} - The processed stats data.
 */
const process_stats = (data) => {
    const timestamp = new Date().toLocaleTimeString();

    const cpu_usage_percent = data.cpu_usage_percent || 0;
    const memory_usage_mb = (data.memory_usage_mb || 0) / (1024 ** 2);
    const memory_limit_mb = (data.memory_limit_mb || 0) / (1024 ** 2);
    let memory_usage_percent = 0.0;
    if (memory_limit_mb > 0) {
        memory_usage_percent = (memory_usage_mb / memory_limit_mb) * 100;
    }

    const network_io_mb = parseFloat((data.network_io_mb || 0).toFixed(6));

    return {
        timestamp,
        cpu_usage_percent: parseFloat(cpu_usage_percent.toFixed(2)),
        memory_usage_percent: parseFloat(memory_usage_percent.toFixed(2)),
        memory_usage_mb: parseFloat(memory_usage_mb.toFixed(2)),
        memory_limit_mb: parseFloat(memory_limit_mb.toFixed(2)),
        network_io_mb,
    };
};

/**
 * ChartBanner component for collapsible chart sections
 */
const ChartBanner = ({ title, children, expanded, onClick }) => {
    const theme = useTheme();
    
    return (
        <Box sx={{ mb: 2 }}>
            <Box 
                onClick={onClick}
                sx={{
                    p: 2,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                    cursor: 'pointer',
                    '&:hover': {
                        bgcolor: 'action.hover',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Typography variant="subtitle1">
                    {title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    {expanded ? 'Click to collapse' : 'Click to expand'}
                </Typography>
            </Box>
            {expanded && (
                <Box sx={{ mt: 2 }}>
                    {children}
                </Box>
            )}
        </Box>
    );
};

/**
 * Stats component connects to a WebSocket and displays server statistics.
 * @param {Object} props
 * @param {string} props.container_name - The name of the container to subscribe to.
 * @returns {JSX.Element}
 */
function Stats({ container_name }) {
    const theme = useTheme();
    const [stats, set_stats] = useState([]);
    const socketRef = useRef(null);
    const reconnectTimeoutRef = useRef(null);
    const [expandedCharts, setExpandedCharts] = useState({
        cpu: false,
        memory: false,
        network_io: false
    });

    const toggleChart = (chartId) => {
        setExpandedCharts(prev => ({
            ...prev,
            [chartId]: !prev[chartId]
        }));
    };

    /**
     * Attempts to reconnect to the WebSocket server
     */
    const attemptReconnect = useCallback(() => {
        if (reconnectTimeoutRef.current) {
            return; // Already attempting to reconnect
        }

        console.log("Scheduling reconnection attempt...");
        reconnectTimeoutRef.current = setTimeout(() => {
            console.log("Attempting to reconnect...");
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
            initialize_socket();
            reconnectTimeoutRef.current = null;
        }, RECONNECT_DELAY);
    }, []);

    /**
     * Initializes the WebSocket connection.
     */
    const initialize_socket = useCallback(() => {
        const socket_instance = io(SOCKET_URL, {
            path: '/socket.io',
            transports: ['websocket']
        });

        socket_instance.on('connect', () => {
            console.log('Connected to stats WebSocket');
            if (container_name) {
                socket_instance.emit('subscribe', { container_name });
            } else {
                console.error('Subscription failed: container_name is undefined.');
                attemptReconnect();
            }
        });

        socket_instance.on('welcome', (data) => {
            console.log("Server says:", data.message);
        });

        socket_instance.on('subscribed', (data) => {
            console.log(`Subscribed to container: ${data.container}`);
        });

        socket_instance.on('stats', (data) => {
            console.log("New stat:", data);
            const new_stat = process_stats(data);
            
            set_stats((prev_stats) => {
                const updated_stats = [...prev_stats, new_stat];
                return updated_stats.length > MAX_DATA_POINTS ? updated_stats.slice(1) : updated_stats;
            });
        });

        // Handle any connection issues
        socket_instance.on('error', (data) => {
            console.error("Socket error:", data.error);
            attemptReconnect();
        });

        socket_instance.on('disconnect', (reason) => {
            console.log(`Disconnected: ${reason}`);
            attemptReconnect();
        });

        socket_instance.on('connect_error', (error) => {
            console.error("Connection error:", error);
            attemptReconnect();
        });

        socketRef.current = socket_instance;
    }, [container_name, attemptReconnect]);

    useEffect(() => {
        initialize_socket();

        return () => {
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            if (socketRef.current && container_name) {
                socketRef.current.emit('unsubscribe', { container_name });
                socketRef.current.disconnect();
                console.log('Disconnected from stats WebSocket');
            }
        };
    }, [container_name, initialize_socket]);

    /**
     * Calculates the data for CPU usage chart.
     * @returns {Array<Object>} - The data array for CPU usage.
     */
    const get_cpu_data = () => {
        return stats.map((entry) => ({
            timestamp: entry.timestamp,
            cpu_usage_percent: entry.cpu_usage_percent || 0,
        }));
    };

    /**
     * Calculates the data for Memory usage chart.
     * @returns {Array<Object>} - The data array for Memory usage.
     */
    const get_memory_data = () => {
        return stats.map((entry) => ({
            timestamp: entry.timestamp,
            memory_usage_percent: entry.memory_usage_percent || 0,
        }));
    };

    /**
     * Calculates the data for Network I/O chart.
     * @returns {Array<Object>} - The data array for Network I/O.
     */
    const get_network_io_data = () => {
        return stats.map((entry) => ({
            timestamp: entry.timestamp,
            network_io_mb: entry.network_io_mb || 0,
        }));
    };

    /**
     * Formats the Network I/O value for display.
     * @param {number} value - The network I/O value in MB/s.
     * @returns {string} - The formatted network I/O string.
     */
    const formatNetworkIO = (value) => {
        const kb = value * 1000;
        if (kb < 1000) {
            return `${kb.toFixed(0)} kb/s`;
        } else {
            const mb = kb / 1000;
            return `${mb.toFixed(1)} mb/s`;
        }
    };

    const CPU_METRIC_LABEL = 'CPU Usage (%)';
    const MEMORY_METRIC_LABEL = 'Memory Usage (%)';
    const NETWORK_IO_LABEL = 'Network I/O';

    const chart_config = {
        width: 500,
        height: 300,
        margin: { top: 20, right: 30, left: 20, bottom: 5 }
    };

    /**
     * Determines the maximum Network I/O value for dynamic Y-axis scaling.
     * @returns {number} - The maximum value for the Y-axis.
     */
    const get_network_io_max = () => {
        const max = Math.max(...stats.map(entry => entry.network_io_mb), 0);
        return max > 0 ? Math.ceil(max * 1.2) : 10;
    };

    return (
        <Paper elevation={3} sx={{ padding: theme.spacing(3), marginTop: theme.spacing(4) }}>
            <Typography variant="h6" gutterBottom>
                Server Statistics
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ChartBanner 
                        title={CPU_METRIC_LABEL}
                        expanded={expandedCharts.cpu}
                        onClick={() => toggleChart('cpu')}
                    >
                        <LineChart
                            {...chart_config}
                            data={get_cpu_data()}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="timestamp"
                                tick={{ fontSize: 12 }}
                                interval={Math.floor(get_cpu_data().length / 5)}
                            />
                            <YAxis 
                                domain={[0, 100]}
                                tick={{ fontSize: 12 }}
                            />
                            <Tooltip />
                            <Legend />
                            <Line 
                                type="stepAfter"
                                dataKey="cpu_usage_percent" 
                                stroke="#8884d8"
                                dot={false}
                                isAnimationActive={false}
                            />
                        </LineChart>
                    </ChartBanner>
                </Grid>
                <Grid item xs={12}>
                    <ChartBanner 
                        title={MEMORY_METRIC_LABEL}
                        expanded={expandedCharts.memory}
                        onClick={() => toggleChart('memory')}
                    >
                        <LineChart
                            {...chart_config}
                            data={get_memory_data()}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="timestamp"
                                tick={{ fontSize: 12 }}
                                interval={Math.floor(get_memory_data().length / 5)}
                            />
                            <YAxis 
                                domain={[0, 100]}
                                tick={{ fontSize: 12 }}
                            />
                            <Tooltip />
                            <Legend />
                            <Line 
                                type="stepAfter"
                                dataKey="memory_usage_percent" 
                                stroke="#82ca9d"
                                dot={false}
                                isAnimationActive={false}
                            />
                        </LineChart>
                    </ChartBanner>
                </Grid>
                <Grid item xs={12}>
                    <ChartBanner 
                        title={NETWORK_IO_LABEL}
                        expanded={expandedCharts.network_io}
                        onClick={() => toggleChart('network_io')}
                    >
                        <LineChart
                            {...chart_config}
                            data={get_network_io_data()}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis 
                                dataKey="timestamp"
                                tick={{ fontSize: 12 }}
                                interval={Math.floor(get_network_io_data().length / 5)}
                            />
                            <YAxis 
                                domain={[0, get_network_io_max()]}
                                tickFormatter={formatNetworkIO}
                                tick={{ fontSize: 12 }}
                            />
                            <Tooltip 
                                formatter={(value) => formatNetworkIO(value)}
                            />
                            <Legend />
                            <Line 
                                type="linear"
                                dataKey="network_io_mb" 
                                stroke="#ff7300"
                                dot={false}
                                isAnimationActive={false}
                            />
                        </LineChart>
                    </ChartBanner>
                </Grid>
            </Grid>
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: theme.spacing(2) }}>
                Click on each metric banner to expand or collapse its chart
            </Typography>
        </Paper>
    );
}

export default Stats;