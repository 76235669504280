import React, { useState } from 'react';
import { useParams, Routes, Route, Link } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CssBaseline,
  Divider,
  Button,
  Box,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PeopleIcon from '@mui/icons-material/People';
import FolderIcon from '@mui/icons-material/Folder';
import BackupIcon from '@mui/icons-material/Backup';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Importing the newly created Logs component
import Logs from './Logs';
import Settings from './Settings';
import Backups from './Backups';
// Import other necessary components as needed
// import Logs from './Logs';
// import ServerPlayers from './ServerPlayers';
// import ServerFiles from './ServerFiles';

const DRAWER_WIDTH = 240;

/**
 * Dashboard component manages the dashboard layout with a responsive drawer.
 * @param {Object} props
 * @param {string} props.user_id - The ID of the current user.
 * @returns {JSX.Element}
 */
function Dashboard({ user_id }) {
  const { client_server_ip } = useParams();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [mobile_open, set_mobile_open] = useState(false);
  const [desktop_open, set_desktop_open] = useState(true);

  /**
   * Toggles the state of the drawer based on the screen size.
   */
  const handle_drawer_toggle = () => {
    if (isDesktop) {
      set_desktop_open(!desktop_open);
    } else {
      set_mobile_open(!mobile_open);
    }
  };

  const navigation_items = [
    { text: 'Settings', path: '', icon: <SettingsIcon /> },
    { text: 'Logs & Commands', path: 'logs', icon: <ListAltIcon /> },
    { text: 'Players', path: 'players', icon: <PeopleIcon /> },
    { text: 'Server Files', path: 'files', icon: <FolderIcon /> },
    { text: 'Backups', path: 'backups', icon: <BackupIcon /> },
  ];

  const drawer_content = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
          Dashboard
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        {navigation_items.map((item) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={`/server/${client_server_ip}/${item.path}`}
            onClick={() => {
              if (!isDesktop) {
                set_mobile_open(false);
              }
            }}
            sx={{
              '&:hover': {
                backgroundColor: theme.palette.action.hover,
              },
              '& .MuiListItemText-primary': {
                color: theme.palette.text.primary,
              },
              '&.Mui-selected, &:visited': {
                '& .MuiListItemText-primary': {
                  color: theme.palette.primary.main,
                },
              },
            }}
          >
            <ListItemIcon sx={{ color: theme.palette.text.primary }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: desktop_open ? `calc(100% - ${DRAWER_WIDTH}px)` : '100%' },
          ml: { md: desktop_open ? `${DRAWER_WIDTH}px` : 0 },
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handle_drawer_toggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {client_server_ip}
          </Typography>
          <Button color="inherit" component={Link} to="/server">
            Your Servers
          </Button>
        </Toolbar>
      </AppBar>

      <Drawer
        variant={isDesktop ? 'persistent' : 'temporary'}
        open={isDesktop ? desktop_open : mobile_open}
        onClose={handle_drawer_toggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: DRAWER_WIDTH,
            transition: theme.transitions.create(['width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        }}
      >
        {drawer_content}
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: theme.spacing(3),
          marginTop: theme.spacing(8),
          marginLeft: { md: desktop_open ? `${DRAWER_WIDTH}px` : 0 },
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Routes>
          <Route
            path="/"
            element={<Settings user_id={user_id} client_server_ip={client_server_ip} />}
          />
          <Route
            path="logs"
            element={<Logs container_name={client_server_ip} client_server_ip={client_server_ip} />}
          />
          {/* Uncomment and implement these routes as needed */}
          {/* 
          <Route path="players" element={<ServerPlayers user_id={user_id} client_server_ip={client_server_ip} />} />
          <Route path="files" element={<ServerFiles user_id={user_id} client_server_ip={client_server_ip} />} />
          */}
          <Route
            path="backups"
            element={<Backups user_id={user_id} client_server_ip={client_server_ip} />}
          />
        </Routes>
      </Box>
    </Box>
  );
}

export default Dashboard;