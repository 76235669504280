import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Grid,
  TextField,
  Button,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material';
import ServerPropertiesForm from './ServerPropertiesForm';
import { DEFAULT_PROPERTIES } from './serverProperties';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const MAX_RAM = 1; // Define appropriate maximums
const MAX_CPU = 1;

/**
 * ServerCreation component allows users to create a new server with specified configurations.
 *
 * @param {Object} props
 * @param {string} props.user_id - The ID of the user creating the server.
 * @returns {JSX.Element}
 */
function ServerCreation({ user_id }) {
  const [client_subdomain, set_client_subdomain] = useState('');
  const [ram, set_ram] = useState(1);
  const [cpu, set_cpu] = useState(1);
  const [server_properties, set_server_properties] = useState(DEFAULT_PROPERTIES);
  const [versions_by_type, set_versions_by_type] = useState({});
  const [selected_type, set_selected_type] = useState('');
  const [selected_version, set_selected_version] = useState('');
  const [eula_accepted, set_eula_accepted] = useState(false);
  const [available_versions, set_available_versions] = useState([]);
  const theme = useTheme();
  const navigate = useNavigate();

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  /**
   * Enforces lowercase alphanumeric, underscores, and hyphens.
   * Spaces are removed, uppercase is converted to lowercase,
   * and invalid characters display an error message.
   * Additionally, enforces a minimum length of 3 and a maximum length of 16.
   */
  const handle_subdomain_change = (e) => {
    let inputValue = e.target.value;

    // Remove spaces
    inputValue = inputValue.replace(/\s+/g, '');
    // Convert uppercase to lowercase
    inputValue = inputValue.toLowerCase();

    // Keep only valid characters (a-z, 0-9, underscore, hyphen)
    let validValue = inputValue.replace(/[^a-z0-9_-]/g, '');
    if (validValue !== inputValue) {
      setSnackbar({
        open: true,
        message: 'Subdomain must contain only lowercase letters, numbers, underscores, or hyphens.',
        severity: 'error',
      });
    }

    // Enforce min and max length
    if (validValue.length > 16) {
      validValue = validValue.slice(0, 16);
      setSnackbar({
        open: true,
        message: 'Subdomain cannot exceed 16 characters; it was truncated.',
        severity: 'error',
      });
    }

    set_client_subdomain(validValue);
  };

  useEffect(() => {
    const fetch_versions = async () => {
      try {
        const response = await axios.get('/api/versions');
        set_versions_by_type(response.data.versions);
        // Set paper as default type if available
        if (response.data.versions.paper) {
          set_selected_type('paper');
          set_available_versions(response.data.versions.paper);
          // Set latest paper version as default
          if (response.data.versions.paper.length > 0) {
            set_selected_version(response.data.versions.paper[0]);
          }
        } else {
          // Fallback to first available type
          const types = Object.keys(response.data.versions);
          if (types.length > 0) {
            set_selected_type(types[0]);
          }
        }
      } catch (error) {
        console.error('Error fetching versions:', error);
      }
    };

    fetch_versions();
  }, []);

  useEffect(() => {
    if (selected_type && versions_by_type[selected_type]) {
      set_available_versions(versions_by_type[selected_type]);
      // Only reset selected version if changing away from initial paper selection
      if (selected_type !== 'paper') {
        set_selected_version('');
      }
    }
  }, [selected_type, versions_by_type]);

  /**
   * Handles the server creation process.
   *
   * @param {React.FormEvent} e - The form submission event.
   */
  const handle_create_server = async (e) => {
    e.preventDefault();

    // Check for min length
    if (client_subdomain.length < 3) {
      setSnackbar({
        open: true,
        message: 'Subdomain must be at least 3 characters to create a server.',
        severity: 'error',
      });
      return;
    }

    if (!eula_accepted) {
      setSnackbar({
        open: true,
        message: 'You must accept the EULA to create a server.',
        severity: 'error',
      });
      return;
    }

    try {
      await create_server();
      setSnackbar({
        open: true,
        message: 'Server created successfully!',
        severity: 'success',
      });
      // Automatically navigate to the settings page (the Dashboard defaults to Settings)
      // assuming client_subdomain is the route param used in /server/:client_server_ip
      navigate(`/server/${client_subdomain}.2layers.io/logs`);
    } catch (error) {
      console.error('Error creating server:', error);
      // Display the error returned by the server if present
      const errorData = error.response?.data;
      const errorMessage =
        errorData?.error ||
        (Array.isArray(errorData?.errors) ? errorData.errors.join(', ') : errorData?.errors) ||
        'Failed to create server.';

      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error',
      });
    }
  };

  /**
   * Creates the server by sending a POST request to the API.
   *
   * @throws Will throw an error if the server creation fails.
   */
  const create_server = async () => {
    const response = await axios.post('/api/create_server', {
      user_id,
      client_subdomain,
      ram,
      cpu,
      server_type: selected_type,
      server_version: selected_version,
      server_properties,
    });
    return response.data;
  };

  return (
    <Paper elevation={3} sx={{ padding: theme.spacing(3), maxWidth: 800, margin: 'auto' }}>
      <form onSubmit={handle_create_server}>
        <Grid container spacing={3}>
          {/* Server Details */}
          <Grid item xs={12}>
            <Typography variant="h6">Server Details</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Your Server Name"
              variant="outlined"
              fullWidth
              value={client_subdomain}
              onChange={handle_subdomain_change}
              required
              InputProps={{
                endAdornment: <InputAdornment position="end">.2layers.io</InputAdornment>,
              }}
            />
          </Grid>

          {/* Server Resources */}
          <Grid item xs={12}>
            <Typography variant="h6">Server Resources</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="RAM (GB)"
              variant="outlined"
              type="number"
              fullWidth
              value={ram}
              onChange={(e) => set_ram(Math.min(Number(e.target.value), MAX_RAM))}
              inputProps={{ min: 1, max: MAX_RAM }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="CPU Cores"
              variant="outlined"
              type="number"
              fullWidth
              value={cpu}
              onChange={(e) => set_cpu(Math.min(Number(e.target.value), MAX_CPU))}
              inputProps={{ min: 1, max: MAX_CPU }}
            />
          </Grid>

          {/* Server Version Selection */}
          <Grid item xs={12}>
            <Typography variant="h6">Server Version</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Server Type"
              variant="outlined"
              fullWidth
              value={selected_type}
              onChange={(e) => set_selected_type(e.target.value)}
              required
            >
              {Object.keys(versions_by_type).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label="Version"
              variant="outlined"
              fullWidth
              value={selected_version}
              onChange={(e) => set_selected_version(e.target.value)}
              required
              disabled={!selected_type}
            >
              {available_versions.map((version) => (
                <MenuItem key={version} value={version}>
                  {version}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Server Properties */}
          <Grid item xs={12}>
            <Typography variant="h6">Server Properties</Typography>
          </Grid>
          <Grid item xs={12}>
            <ServerPropertiesForm
              properties={server_properties}
              onChange={set_server_properties}
            />
          </Grid>

          {/* EULA Checkbox */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={eula_accepted}
                  onChange={(e) => set_eula_accepted(e.target.checked)}
                />
              }
              label="I accept the EULA"
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
            >
              Create Server
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '90%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default ServerCreation;