import React from 'react';
import {
  Typography,
  Container,
  Box,
  Grid,
  Paper,
  Button,
  Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function WelcomePage() {
  const theme = useTheme();

  // Check localStorage for a token to see if user is logged in
  const token = localStorage.getItem('token');
  const isLoggedIn = Boolean(token);

  return (
    <Container maxWidth="md" sx={{ marginTop: theme.spacing(12), textAlign: 'center' }}>
      {/* Hero Section */}
      <Box
        sx={{
          mb: 6,
          p: 4,
          borderRadius: 2,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Typography variant="h3" gutterBottom>
          2Layers Minecraft Server Hosting
        </Typography>

        {isLoggedIn ? (
          <>
            {/* Logged In */}
            <Typography variant="h5" gutterBottom>
              Welcome back!
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              Ready to manage your servers? Let’s get started!
            </Typography>
            <Button
              component={Link}
              to="/server"
              variant="contained"
              color="primary"
              sx={{ marginTop: 2 }}
            >
              Manage My Servers
            </Button>
          </>
        ) : (
          <>
            {/* Not Logged In */}
            <Typography variant="h5" gutterBottom>
              Ready to begin?
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              Sign in to start hosting your Minecraft servers.
            </Typography>
            <Button
              component={Link}
              to="/login"
              variant="contained"
              color="primary"
              sx={{ marginTop: 2 }}
            >
              Start Hosting
            </Button>
          </>
        )}
      </Box>

      {/* Subscription Plans */}
      <Box mb={6}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
          <Typography variant="h4" gutterBottom>
            Our Subscription Plans
          </Typography>
          <Tooltip
            title={
              <React.Fragment>
                <Typography variant="body2">
                  2Layers is brand new this year (founded in 2024) and is run
                  solo, focusing on building a straightforward, user-driven service.
                  Since we're in alpha, the service is provided as-is—free for
                  now—while we gather initial feedback. Servers may be cleared
                  at times as we solidify the foundation. We welcome all comments
                  or requests at <strong>u-evol-guy@proton.me</strong>.
                </Typography>
              </React.Fragment>
            }
            enterTouchDelay={50}
            leaveTouchDelay={1500}
          >
            <HelpOutlineIcon
              fontSize="small"
              sx={{ ml: 1, cursor: 'pointer' }}
              aria-label="Why no subscriptions?"
            />
          </Tooltip>
        </Box>
        <Typography variant="body1" color="textSecondary" paragraph>
          Choose a simple monthly subscription based on your server needs.
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {/* Tester (Free) */}
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} sx={{ padding: theme.spacing(2) }}>
              <Typography variant="h6">Tester</Typography>
              <Typography variant="body2" color="textSecondary">
                1 CPU • 1 GB RAM
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                $0 / month**
              </Typography>
            </Paper>
          </Grid>

          {/* Starter */}
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} sx={{ padding: theme.spacing(2), position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: 'error.main',
                  color: 'white',
                  padding: theme.spacing(0.5, 1),
                  borderRadius: '0 0 4px 0',
                  fontWeight: 'bold'
                }}
              >
                Coming Soon
              </Box>
              <Typography variant="h6">Starter</Typography>
              <Typography variant="body2" color="textSecondary">
                1 CPU • 2 GB RAM
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                $8 / month
              </Typography>
            </Paper>
          </Grid>

          {/* Basic */}
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} sx={{ padding: theme.spacing(2), position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: 'error.main',
                  color: 'white',
                  padding: theme.spacing(0.5, 1),
                  borderRadius: '0 0 4px 0',
                  fontWeight: 'bold'
                }}
              >
                Coming Soon
              </Box>
              <Typography variant="h6">Basic</Typography>
              <Typography variant="body2" color="textSecondary">
                1 CPU • 4 GB RAM
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                $16 / month
              </Typography>
            </Paper>
          </Grid>

          {/* Standard */}
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} sx={{ padding: theme.spacing(2), position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: 'error.main',
                  color: 'white',
                  padding: theme.spacing(0.5, 1),
                  borderRadius: '0 0 4px 0',
                  fontWeight: 'bold'
                }}
              >
                Coming Soon
              </Box>
              <Typography variant="h6">Standard</Typography>
              <Typography variant="body2" color="textSecondary">
                1 CPU • 8 GB RAM
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                $32 / month
              </Typography>
            </Paper>
          </Grid>

          {/* Premium */}
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} sx={{ padding: theme.spacing(2), position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: 'error.main',
                  color: 'white',
                  padding: theme.spacing(0.5, 1),
                  borderRadius: '0 0 4px 0',
                  fontWeight: 'bold'
                }}
              >
                Coming Soon
              </Box>
              <Typography variant="h6">Premium</Typography>
              <Typography variant="body2" color="textSecondary">
                1 CPU • 16 GB RAM
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                $64 / month
              </Typography>
            </Paper>
          </Grid>

          {/* Premium+ CPU (commented out) */}
          {/*
          <Grid item xs={12} sm={4}>
            <Paper elevation={3} sx={{ padding: theme.spacing(2), position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  backgroundColor: 'error.main',
                  color: 'white',
                  padding: theme.spacing(0.5, 1),
                  borderRadius: '0 0 4px 0',
                  fontWeight: 'bold'
                }}
              >
                Coming Soon
              </Box>
              <Typography variant="h6">Premium+ CPU</Typography>
              <Typography variant="body2" color="textSecondary">
                2 CPU • 16 GB RAM
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                $72 / month
              </Typography>
            </Paper>
          </Grid>
          */}
        </Grid>

        {/* Note for Free Tester Option */}
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary">
            ** The Tester plan is free but runs for up to four hours before returning to a queue.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default WelcomePage;
