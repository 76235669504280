 // Start of Selection
import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, CssBaseline, Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';

import theme from './theme';
import WelcomePage from './components/WelcomePage';
import ServerList from './components/ServerList';
import Dashboard from './components/Dashboard/Dashboard';
import ServerCreation from './components/server/ServerCreation';
import Login from './components/Login';

function App() {
  const [user_id, set_user_id] = useState('');
  const navigate = useNavigate();

  // Initialize authentication state on app load
  useEffect(() => {
    const initialize_auth = () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decoded = jwt_decode(token);
          // Check token expiration
          if (decoded.exp * 1000 > Date.now()) {
            set_user_id(decoded.sub);
          } else {
            localStorage.removeItem('token');
          }
        } catch (error) {
          console.error('Invalid token:', error);
          localStorage.removeItem('token');
        }
      }
    };

    initialize_auth();
  }, []);

  const handle_login = () => {
    navigate('/login');
  };

  const handle_logout = () => {
    localStorage.removeItem('token');
    set_user_id('');
    navigate('/');
  };

  const render_navigation_buttons = () => {
    if (!user_id) {
      return (
        <Button color="inherit" onClick={handle_login}>
          Login
        </Button>
      );
    }

    return (
      <>
        {!window.location.pathname.startsWith('/server') && (
          <Button color="inherit" onClick={() => navigate('/server')}>
            Your Servers
          </Button>
        )}
        {window.location.pathname.startsWith('/server') && (
          <Button color="inherit" onClick={() => navigate('/')}>
            Home
          </Button>
        )}
        <Button color="inherit" onClick={handle_logout}>
          Logout
        </Button>
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GoogleOAuthProvider clientId="625955146747-4368c7tbpner441520u5vuc6m0jn8t1s.apps.googleusercontent.com">
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <AppBar position="fixed">
            <Toolbar>
              <Typography
                variant="h6"
                sx={{ flexGrow: 1, fontWeight: 'bold', cursor: 'pointer' }}
                onClick={() => navigate('/')}
              >
                2Layers
              </Typography>
              {render_navigation_buttons()}
            </Toolbar>
          </AppBar>
          <Box component="main" sx={{ flexGrow: 1, mt: '64px' }}>
            <Routes>
              <Route path="/" element={<WelcomePage />} />
              <Route
                path="/server/*"
                element={user_id ? <ServerList user_id={user_id} /> : <WelcomePage />}
              />
              <Route path="/login" element={<Login set_user_id={set_user_id} />} />
              <Route
                path="/create_server"
                element={user_id ? <ServerCreation user_id={user_id} /> : <WelcomePage />}
              />
              <Route
                path="/server/:client_server_ip/*"
                element={user_id ? <Dashboard user_id={user_id} /> : <WelcomePage />}
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Box>
        </Box>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
