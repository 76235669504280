import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Typography,
  Grid,
  Paper,
  Container,
  IconButton,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useTheme } from '@mui/material/styles';

function ServerList({ user_id }) {
  const [servers, set_servers] = useState([]);
  const [is_loading, set_is_loading] = useState(true);

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const fetch_servers = async () => {
      try {
        const response = await axios.get('/api/server_list', { params: { user_id } });
        set_servers(response.data.servers);
      } catch (error) {
        console.error('Error fetching servers:', error);

        let errorMsg = 'Failed to load servers.';
        if (error.response && error.response.data) {
          if (error.response.data.error) {
            errorMsg = error.response.data.error;
          } else if (typeof error.response.data === 'string') {
            errorMsg = error.response.data;
          }
        }

        setSnackbar({
          open: true,
          message: errorMsg,
          severity: 'error',
        });
      } finally {
        set_is_loading(false);
      }
    };

    fetch_servers();
  }, [user_id]);

  const handle_create_server = () => {
    navigate('/create_server');
  };

  const handle_server_click = (client_server_ip) => {
    navigate(`/server/${client_server_ip}`);
  };

  const handle_toggle_server = async (client_server_ip) => {
    set_is_loading(true);
    try {
      const toggleResponse = await axios.post('/api/toggle_server', { user_id, client_server_ip });

      // Refresh the server list after toggling
      const response = await axios.get('/api/server_list', { params: { user_id } });
      set_servers(response.data.servers);

      // Provide feedback if toggleResponse has any message from backend
      setSnackbar({
        open: true,
        message: toggleResponse.data?.message || 'Server toggled successfully.',
        severity: 'success',
      });
    } catch (error) {
      console.error('Error toggling server:', error);

      let errorMsg = 'Error toggling server.';
      if (error.response && error.response.data && error.response.data.error) {
        errorMsg = error.response.data.error;
      } else if (error.response && typeof error.response.data === 'string') {
        errorMsg = error.response.data;
      }

      setSnackbar({
        open: true,
        message: errorMsg,
        severity: 'error',
      });
    } finally {
      set_is_loading(false);
    }
  };

  if (is_loading) {
    return (
      <Grid container justifyContent="center" sx={{ marginTop: theme.spacing(4) }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Container maxWidth="md" sx={{ marginTop: theme.spacing(6), marginBottom: theme.spacing(6) }}>
        {servers.length === 0 ? (
          <Typography variant="h6" align="center" color="textSecondary">
            You have no servers. Click below to create one.
          </Typography>
        ) : (
          <Grid container spacing={3}>
            {servers.map((server) => (
              <Grid item xs={12} sm={6} key={server.CLIENT_SERVER_IP}>
                <Paper
                  variant="outlined"
                  sx={{
                    padding: theme.spacing(2),
                    cursor: 'pointer',
                    '&:hover': { boxShadow: 6 },
                  }}
                  onClick={() => handle_server_click(server.CLIENT_SERVER_IP)}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box>
                      <Typography variant="h6">{server.CLIENT_SERVER_NAME}</Typography>
                      <Typography variant="body2">IP: {server.CLIENT_SERVER_IP}</Typography>
                      <Typography variant="body2">STATUS: {server.STATUS}</Typography>
                    </Box>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handle_toggle_server(server.CLIENT_SERVER_IP);
                      }}
                      sx={{
                        color: server.STATUS === 'ONLINE' ? 'success.main' : 'error.main',
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.08)' },
                      }}
                    >
                      <PowerSettingsNewIcon />
                    </IconButton>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
        <Box sx={{ marginTop: theme.spacing(4) }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handle_create_server}
            fullWidth
            size="large"
          >
            Create New Server
          </Button>
        </Box>
      </Container>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '90%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ServerList;
